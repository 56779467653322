import React from "react";
import "./home.scss";
import { Card } from "../../components/card/Card";

export const Home = () => {
	return (
		<div className="home">
			<div className="header">
				<div className="profile-img">
					<img
						className="h-32 w-32 rounded-full"
						src="https://avatars.githubusercontent.com/u/65531408?v=4"
						alt="profile"
					/>
				</div>
				<Card className="contact-info">
					<h1>Contact</h1>
				</Card>
			</div>
			<Card className="education m-2">
				<h1>Education</h1>
			</Card>
			<Card className="experience m-2">
				<h1>Experience</h1>
			</Card>
			<Card className="skills m-2">
				<h1>Skills</h1>
			</Card>
			<Card className="projects m-2">
				<h1>Projects</h1>
			</Card>
		</div>
	);
};
