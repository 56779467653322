import React from "react";
import "./App.css";
import { Views } from "./views/Views";
import { BgSquaresGradient } from "./components/bgSquaresGradient/BgSquaresGradient";

function App() {
	return (
		<div className="App">
			<BgSquaresGradient />
			<Views />
		</div>
	);
}

export default App;
