import React from "react";
import "./bg-squares-gradient.scss";

export const BgSquaresGradient = () => {
	return (
		<div className="bg-squares-gradient">
			<ul className="items">
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div>
	);
};
