import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./home/Home";

export const Views = () => {
	return (
		<div className="views flex justify-center">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
};
