import classNames from "classnames";
import React, { HTMLAttributes, PropsWithChildren } from "react";
import "./card.scss";

export const Card: React.FC<
	PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = ({ children, ...props }) => {
	return (
		<div
			{...props}
			className={classNames(
				"card",
				"flex-1 rounded-lg p-2",
				props.className
			)}>
			{children}
		</div>
	);
};
